@import url("https://fonts.googleapis.com/css2?family=Alice&display=swap");
:root {
	--base-clr: #dccb99;
	--body-bg: #0f172a;
	--white-clr: #fff;
	--yellow-clr: rgba(244, 249, 8, 1);
}
body {
	font-family: "Alice", serif;
	padding: 0;
	margin: 0;
	color: var(--white-clr);
	background: var(--body-bg);
	font-size: 32px;
	line-height: 37px;
	@media (max-width: 1199px) {
		font-size: 24px;
		line-height: 34px;
	}
	@media (max-width: 767px) {
		font-size: 20px;
		line-height: 30px;
	}
}
* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	.logo {
		max-width: 291px;
		@media (min-width: 1200px) {
			transform: translateY(-5px);
		}
		a {
			display: block;
			img {
				max-width: 100%;
			}
		}
	}
	background: rgba(#fff, 0.15);
	&.active {
		background: rgba(#1e1e1e, 0.6);
		animation: fadeInUp 0.5s;
		-webkit-animation: fadeInUp 0.5s;
		-moz-animation: fadeInUp 0.5s;
	}
	&:not(.active) {
		animation: fadeInDown 0.5s;
		-webkit-animation: fadeInDown 0.5s;
		-moz-animation: fadeInDown 0.5s;
	}
	padding: 0 5px;
	@media (min-width: 768px) {
		padding: 0 30px;
	}
}
@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-50px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
.menu {
	display: flex;
	flex-wrap: wrap;
	li {
		a {
			color: var(--white-clr);
			font-size: 20px;
			text-transform: capitalize;
			padding: 10px 15px;
			display: inline-block;
			&:hover {
				color: var(--base-clr);
			}
		}
		flex-grow: 1;
	}
	max-width: 1100px;
}
.header-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0;
	.header-bar {
		img {
			max-width: 36px;
		}
	}
	position: relative;
	&::after {
		width: 100%;
		bottom: 0;
		left: 0;
		height: 5px;
		position: absolute;
		content: "";
		display: block;
		background: linear-gradient(
			90deg,
			rgba(194, 177, 133, 0) 0%,
			#c2b185d4 50%,
			rgba(194, 176, 133, 0) 100%
		);
	}
}
@media (max-width: 1250px) {
	header .logo {
		max-width: 240px;
	}
	.menu {
		li {
			a {
				font-size: 17px;
				padding: 8px 12px;
			}
		}
	}
}
@media (max-width: 991px) {
	header .logo {
		max-width: 240px;
	}
	.menu-wrapper {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background: rgba(#111, 0.95);
		display: flex;
		align-items: center;
		justify-content: center;
		overflow-y: auto;
		transition: all ease 0.4s;
		&:not(.active) {
			transform: scale(0.8);
			opacity: 0;
			visibility: hidden;
		}
		.menu {
			padding: 40px 0;
			flex-direction: column;
			text-align: center;
			width: 100%;
			flex-wrap: nowrap;
			li {
				a {
					font-size: 24px;
					padding: 15px;
					&:hover,
					&:focus {
						color: var(--base-clr);
					}
				}
			}
		}
	}
	.close-menu {
		position: fixed;
		right: 20px;
		top: 20px;
		img {
			width: 25px;
		}
	}
}
@media (max-width: 450px) {
	header .logo {
		max-width: 200px;
	}
	.header-bar {
		img {
			max-width: 32px !important;
		}
	}
}
.banner-content {
	max-width: 1230px;
	text-align: center;
	margin: 0 auto;
	padding: 340px 0 190px;
	.text {
		margin: 0 auto;
		max-width: 634px;
		color: var(--white-clr);
		font-size: 24px;
		line-height: 30px;
		margin-top: 25px;
	}
	.banner-logo {
		margin-bottom: 35px;
		img {
			max-width: 100%;
		}
	}
	.banner-play-icon {
		max-width: 500px;
		margin: 140px auto 0;
		position: relative;
		cursor: pointer;
		&::before {
			inset: 0;
			z-index: 1;
			position: absolute;
			content: "";
		}
		img {
			max-width: 100%;
		}
		.icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	@media (max-width: 1399px) {
		padding: 260px 0 100px;
		.banner-play-icon {
			max-width: 400px;
			margin-top: 70px;
		}
	}
	@media (max-width: 1199px) {
		padding: 200px 0 100px;
		.banner-play-icon {
			max-width: 350px;
			margin-top: 50px;
			.icon {
				max-width: 60px;
			}
		}
	}
	@media (max-width: 991px) {
		padding: 150px 0 70px;
		.banner-logo {
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}
		.banner-play-icon {
			max-width: 250px;
			margin-top: 40px;
		}
	}
	@media (max-width: 767px) {
		padding-bottom: 120px;
	}
}
.banner-section {
	position: relative;
	overflow: hidden;
	> .container {
		position: relative;
		z-index: 999;
	}
}
.banner-video {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 120%;
	height: 100%;
	object-fit: cover;
}
.banner-play-icon {
	border-radius: 25px;
	border: 5px solid var(--base-clr);
	&::before {
		inset: 0;
		z-index: 99;
		position: absolute;
		content: "";
	}
}
.video-modal-wrapper {
	position: fixed;
	z-index: 9999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(#111, 0.9);
	.inner {
		max-width: 900px;
		width: 100%;
		position: relative;
		z-index: 1;
		iframe {
			width: 100%;
			height: 100%;
			min-height: 60vh;
		}
	}
	animation: zoomIn 0.5s;
	-webkit-animation: zoomIn 0.5s;
	-moz-animation: zoomIn 0.5s;
	animation-delay: 0.8s;
	-webkit-animation-delay: 0.8s;
	-moz-animation-delay: 0.8s;
}
.close__video {
	position: fixed;
	right: 20px;
	top: 20px;
	z-index: 966;
	cursor: pointer;
	img {
		width: 25px;
	}
}
.banner-social {
	width: 80px;
	height: 93px;
	&::before {
		content: "";
		background: rgba(0, 0, 0, 0.35);
		border-radius: 15px;
		position: absolute;
		inset: 0;
		border: 2px solid var(--base-clr);
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	right: 0;
	top: 50%;
	transform: translate(50%, -50%);
	padding-left: 10px;
	z-index: 999;
	a {
		position: relative;
		z-index: 1;
		&:not(:last-child) {
			margin-bottom: 5px;
		}
		display: inline-block;
		img {
			max-width: 20px;
		}
	}
}
.section-padding {
	padding: 80px 0;
}

.section-header {
	text-align: center;
	margin: 0 auto 25px;
	.title {
		font-size: 30px;
		text-transform: uppercase;
	}
	&::after {
		margin: 25px auto 0;
		width: 80%;
		max-width: 500px;
		height: 5px;
		content: "";
		display: block;
		background: linear-gradient(
			90deg,
			rgba(194, 177, 133, 0) 0%,
			rgba(194, 177, 133, 0.1) 25%,
			#c2b185 50%,
			rgba(194, 176, 133, 0.1) 75%,
			rgba(194, 176, 133, 0) 100%
		);
	}
	@media (min-width: 576px) {
		.title {
			font-size: 36px;
			line-height: 50px;
		}
	}
	@media (min-width: 768px) {
		max-width: 720px;
		margin: 0 auto 60px;
		&::after {
			margin-top: 20px;
		}
	}
	&.text-base {
		.title {
			color: var(--base-clr);
		}
	}
	&.mw-400 {
		max-width: 400px;
	}
}
.banner-section {
	overflow: hidden;
	position: relative;
	&::before {
		position: absolute;
		content: "";
		inset: 0;
		z-index: 99;
	}
	.react-player {
		position: absolute;
		left: -5%;
		top: 0;
		width: 110% !important;
		height: 100% !important;
	}
	.ytp-watermark {
		display: none !important;
	}
}

.anime-wrap {
	height: 100%;
	text-align: center;
	img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}

	.anime-card {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		&:nth-child(2) {
			top: 100%;
		}
		&:nth-child(3) {
			top: 200%;
		}
		&:nth-child(4) {
			top: 300%;
		}
		&:nth-child(5) {
			top: 400%;
		}
		&:nth-child(6) {
			top: 500%;
		}
	}
	position: relative;
	animation: spin 4s linear infinite;
}
@keyframes spin {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(-600%);
	}
}
.anime-box {
	position: relative;
	overflow: hidden;
	height: 300px;
	@media (max-width: 1199px) {
		height: 250px;
	}
	@media (max-width: 991px) {
		height: 220px;
	}
	@media (max-width: 767px) {
		height: 250px;
	}
	@media (max-width: 575px) {
		height: 160px;
	}
}
.section-title {
	text-align: center;
	margin-bottom: 40px;
	@media (max-width: 991px) {
		margin-bottom: 50px;
	}
	position: relative;
	.haku-logo {
		position: absolute;
		right: calc(50% + 80px);
		top: -75px;
		z-index: 9;
		max-width: 372px;
		@media (max-width: 991px) {
			max-width: 300px;
		}
		@media (max-width: 767px) {
			max-width: 200px;
		}
		@media (max-width: 500px) {
			display: none;
		}
	}
}
.count-logo {
	img {
		max-width: 100%;
	}
	margin-bottom: 10px;
}
.countdown {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 40px;
	margin: 0 auto;
	width: 100%;
	max-width: 480px;
	.item {
		padding: 5px;
		margin: 12px;
		text-align: center;
	}
	@media (min-width: 768px) {
		font-size: 36px;
		.item {
			padding: 10px;
		}
	}
	@media (max-width: 375px) {
		font-size: 36px;
	}
	color: var(--yellow-clr);
}
.mt-video {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 110%;
	// height: 100%;
	position: absolute;
	background-size: cover;
	@media (max-width: 1199px) {
		width: 140%;
	}
	@media (max-width: 991px) {
		height: 100%;
		width: unset;
	}
}
.video-txt {
	text-align: center;
	position: relative;
	z-index: 1;
	background: rgba(0, 0, 0, 0.35);
	border: 3px solid var(--base-clr);
	padding: 50px 30px;
	margin-left: auto;
	p {
		margin: 0;
		&:not(:last-child) {
			margin-bottom: 35px;
		}
	}
	@media (min-width: 768px) {
		max-width: 763px;
		padding: 40px 50px;
	}
}

.video-section {
	position: relative;
	padding: 70px 0 80px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	line-height: 25px;
	@media (min-width: 1200px) {
		min-height: 100vh;
	}
	@media (max-width: 991px) {
		padding: 50px 0;
		.video-txt {
			font-size: 20px;
			line-height: 25px;
			padding: 30px 15px;
		}
	}
	@media (max-width: 575px) {
		padding: 50px 0;
		.video-txt {
			font-size: 18px;
			line-height: 1.5;
			padding: 30px 15px;
		}
	}
}
footer {
	padding: 0 0 30px;
}
.footer-wrapper {
	width: calc(100% - 30px);
	margin: 0 auto;
	max-width: 1831px;
	padding: 50px 50px 120px;
	background: #080808;
	position: relative;
	&::before {
		inset: -3px;
		position: absolute;
		content: "";
		z-index: -1;
		background: linear-gradient(
			180deg,
			rgba(194, 177, 133, 0.1) 0%,
			#c2b185d4 50%,
			rgba(194, 176, 133, 0.1) 100%
		);
	}
	@media (max-width: 768px) {
		padding: 50px;
	}
	@media (max-width: 575px) {
		padding: 50px 20px;
	}
}
.brand-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	.item {
		padding: 15px;
		img {
			width: 100%;
			height: 80px;
			object-fit: contain;
		}
		max-width: calc(100% / 3);
		@media (max-width: 575px) {
			max-width: calc(100% / 2);
		}
	}
}
.factions-section {
	position: relative;
	padding-top: 30px;
	padding-bottom: 160px;
	background-position: 100px 0;
	min-height: 115vh;

	&::before {
		position: absolute;
		height: 294px;
		content: "";
		left: 0;
		top: 0;
		background: #000;
		width: 100%;
	}
	.faction-shape {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 240px;
		z-index: 1;
		@media (max-width: 767px) {
			height: 150px;
		}
	}
	@media (max-width: 1199px) {
		padding-bottom: 120px;
	}
	.container,
	.container-fluid {
		position: relative;
		z-index: 3;
	}
	@media (max-width: 767px) {
		.section-header {
			.title {
				font-size: 34px;
			}
		}
	}
}
.no-shape {
	&::after {
		display: none;
	}
}
.faction-nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	li {
		padding: 12px;
		cursor: pointer;
		max-width: 265px;
		transition: all ease 0.3s;
		img {
			max-width: 100%;
		}
		&:not(.active) {
			// opacity: 0.25;
			opacity: 0.35;
		}
		&:hover {
			opacity: 1;
		}
		max-width: calc(100% / 5);
	}
	margin-bottom: 30px;
	@media (max-width: 767px) {
		margin-bottom: 0;
		li {
			padding: 4px;
		}
	}
	@media (max-width: 575px) {
		justify-content: space-evenly;
		li {
			padding: 4px;
			max-width: calc(100% / 3);
			width: 100px;
		}
	}
}
.factions-tab-content {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 1366px;
	margin-left: auto;
	margin-right: auto;
	.sub-wrap {
		align-self: center;
		ul {
			li {
				padding: 10px 0;
				img {
					width: 100%;
				}
			}
		}
		width: 120px;
	}
	.main-wrap {
		width: calc(100% - 120px);
		position: relative;
		@media (min-width: 992px) {
			transform: translateX(10px);
		}
	}
	&.anime {
		.title {
			animation: fadeInUp 0.3s;
		}
		.subtitle {
			animation: fadeInUp 0.3s;
		}
		.main-image {
			animation: fadeInUp 0.3s;
		}
		.sub-wrap {
			li {
				animation: fadeInUp 0.3s;
				-webkit-animation: fadeInUp 0.3s;
				-moz-animation: fadeInUp 0.3s;
				&:nth-child(2) {
					animation-delay: 0.1s;
					-webkit-animation-delay: 0.1s;
					-moz-animation-delay: 0.1s;
				}
				&:nth-child(3) {
					animation-delay: 0.2s;
					-webkit-animation-delay: 0.2s;
					-moz-animation-delay: 0.2s;
				}
			}
		}
	}
	@media (max-width: 1199px) {
		.sub-wrap,
		.main-wrap {
			transform: translateX(0);
			width: 100%;
		}
		.sub-wrap {
			padding-top: 30px;
			margin-bottom: 0px;
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				li {
					img {
						max-width: 120px;
					}
					max-width: calc(100% / 3);
					padding: 15px;
				}
			}
		}
		.main-wrap {
			margin: 0 15px;
		}
	}
	@media (max-width: 991px) {
		.main-wrap {
			border: none;
			padding: 0;
			background: transparent;
		}
	}
	@media (max-width: 767px) {
		.sub-wrap {
			margin-bottom: 0;
		}
	}
}
@keyframes fadeInUp {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
.bg--black-25 {
	background: rgba(#000, 0.25);
}
.main-wrap {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding: 35px 40px;
	.glow-image {
		max-width: 400px;
		animation: glow 2s linear infinite;
		position: absolute;
		top: 70px;
		left: 0;
		right: calc(50% - 45px);
	}
	.main-image-item {
		max-width: 435px;
		height: 435px;
		position: relative;
		z-index: 1;
		padding-left: 30px;
		img {
			animation: upDown 3s alternate infinite;
			-webkit-animation: upDown 3s alternate infinite;
			-moz-animation: upDown 3s alternate infinite;
		}
	}
	position: relative;
	justify-content: space-between;
	.content {
		padding: 20px;
		width: calc(100% - 435px);
		max-width: 650px;
		text-align: center;
		background-size: 100% 100% !important;
		position: relative;
		@media (min-width: 992px) {
			min-height: 400px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}
		&::before {
			border: 3px solid var(--base-clr);
			inset: 0;
			position: absolute;
			content: "";
			z-index: -1;
		}
		.title {
			font-size: 30px;
			text-transform: uppercase;
			position: relative;
			color: var(--base-clr);
			&::after {
				margin: 20px auto 25px;
				width: 80%;
				max-width: 500px;
				height: 5px;
				content: "";
				display: block;
				background: linear-gradient(
					90deg,
					rgba(194, 177, 133, 0) 0%,
					#c2b185d4 50%,
					rgba(194, 176, 133, 0) 100%
				);
			}
		}
		.subtitle {
			font-size: 24px;
			margin-bottom: 20px;
		}
		font-size: 20px;
		line-height: 27.8px;
		p {
			margin-bottom: 10px;
		}
		.cont {
			max-width: 480px;
			margin: 0 auto;
		}
		@media (min-width: 1200px) {
			margin-left: auto;
			margin-right: auto;
			transform: translateX(20px);
		}
	}
	@media (max-width: 1300px) {
		.thumb {
			.glow-image {
				width: 340px;
			}
			.main-image-item {
				width: 340px;
				padding-left: 0px;
				.main-image {
					max-width: 500px;
				}
			}
		}
	}
	@media (max-width: 1199px) {
		.thumb {
			.main-image-item {
				width: 300px;
			}
			.main-image {
				width: 420px;
			}
		}
		.glow-image {
			width: 300px;
		}
		.content {
			flex-grow: 1;
			margin: 0;
			max-width: 100%;
			min-height: 340px;
		}
	}
	@media (max-width: 991px) {
		.content {
			min-height: 0;
			.title {
				font-size: 24px;
				&::after {
					height: 3px;
					margin-top: 5px;
				}
			}
			.subtitle {
				font-size: 18px;
			}
			font-size: 16px;
		}
	}
	@media (max-width: 767px) {
		.thumb {
			width: 100%;
			position: relative;
			margin: 0 auto 10px;
			max-width: 450px;

			.glow-image {
				left: -30px;
				top: 0;
			}
			.main-image-item {
				max-width: 100%;
				width: 100%;
				margin: 0 auto;
				height: unset;
				img {
					width: 100%;
				}
			}
		}
		.content {
			flex-grow: 1;
			margin: 0;
			max-width: 100%;
			width: 100%;
			min-height: 0;
			@media (max-width: 575px) {
				padding: 45px 20px;
				font-size: 16px;
			}
		}
	}
	@media (max-width: 500px) {
		.thumb {
			.main-image-item {
				padding: 0;
				img {
					transform: translateX(50px);
				}
			}
			.glow-image {
				width: 260px;
			}
		}
	}
	@media (max-width: 400px) {
		.thumb {
			.glow-image {
				width: 240px;
			}
		}
	}
}
@keyframes glow {
	0% {
		transform: scale(0.95);
		opacity: 0.3;
	}
	50% {
		transform: scale(1.01);
		opacity: 1;
	}
	100% {
		transform: scale(0.95);
		opacity: 0.3;
	}
}
.gameplay-section {
	padding: 50px 0;
	overflow: hidden;
	background-attachment: fixed !important;
	@media (min-width: 768px) {
		min-height: 100vh;
	}
}
.gameplay-slider {
	max-width: 845px;
	margin: auto auto 0;
	padding: 0;
}
.slide-item {
	text-align: center;
	img {
		width: 100%;
	}
	.title {
		max-width: 590px;
		margin: 0 auto;
		font-size: 20px;
		line-height: 30px;
	}
	.img-container {
		margin-bottom: 30px;
		@media (min-width: 1200px) {
			margin-bottom: 60px;
		}
		.prev-btn,
		.next-btn {
			max-width: 33px;
			cursor: pointer;
			@media (max-width: 575px) {
				width: 25px;
			}
		}
	}
	@media (max-width: 575px) {
		.title {
			font-size: 18px;
		}
	}
}
.roadmap-section {
	padding: 80px 0;
	@media (min-width: 1400px) {
		padding-bottom: 300px;
	}
}
.roadmap-item {
	text-align: center;
	transition: all ease 0.3s;
	&:hover {
		transform: scale(1.1);
	}
	.title {
		font-size: 26px;
		line-height: 1.3;
		color: var(--base-clr);
		position: relative;
		&::after {
			margin: 7px auto 35px;
			width: 80%;
			max-width: 140px;
			height: 4px;
			content: "";
			display: block;
			background: linear-gradient(
				90deg,
				rgba(194, 177, 133, 0) 0%,
				rgba(194, 176, 133, 0.15) 25%,
				#c2b185d4 50%,
				rgba(194, 176, 133, 0.15) 75%,
				rgba(194, 176, 133, 0) 100%
			);
		}
		small {
			font-size: 70%;
		}
	}
	ul {
		font-size: 18px;
		line-height: 1.6;
		li {
			padding: 6px 0;
			svg {
				font-size: 18px;
				margin-right: 4px;
				color: var(--base-clr);
			}
			small {
				svg {
					font-size: 70%;
					color: var(--white-clr);
				}
			}
		}
	}
	height: 100%;
	padding: 60px 30px;
	ul {
		margin-top: 20px;
	}
	min-height: 500px;
}
.roadmap-wrapper {
	max-width: 1690px;
	margin: 0 auto;
}
.team-section {
	position: relative;
	.team-shape-img {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
	}
	.team-shape-img-2 {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		width: 100%;
		height: 100%;
		min-width: 1920px;
	}
	padding: 100px 0 700px;
}
.team__item {
	text-align: center;
	&-img {
		max-width: 150px;
		margin: 0 auto 20px;
		img {
			max-width: 100%;
			border-radius: 50%;
			aspect-ratio: 1;
			object-fit: cover;
		}
	}
	.name {
		color: var(--base-clr);
		font-size: 24px;
	}
	.designation {
		font-size: 18px;
		display: block;
	}
	.info {
		display: block;
		font-size: 16px;
		line-height: 30px;
		margin-top: 20px;
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}
	@media (max-width: 991px) {
		.designation,
		.info {
			font-size: 18px;
			line-height: 1.4;
		}
		.team__item-img {
			margin: 0 auto 20px;
		}
	}
}
.team-tab-content {
	max-width: 1462px;
	margin: 0 auto;
	.active {
		animation: zoomIn 0.3s;
		-webkit-animation: zoomIn 0.3s;
		-moz-animation: zoomIn 0.3s;
	}
	.show {
		animation: zoomIn2 0.3s;
		-webkit-animation: zoomIn2 0.3s;
		-moz-animation: zoomIn2 0.3s;
	}
}
.tab-menu {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-bottom: 45px;
	li {
		font-size: 30px;
		text-transform: uppercase;
		padding: 10px 20px;
		border: 2px solid var(--base-clr);
		margin: 10px;
		flex-grow: 1;
		max-width: 358px;
		border-radius: 10px;
		text-align: center;
		&:first-child {
			border-radius: 35px 7px 7px 0;
		}
		&:last-child {
			border-radius: 7px 35px 0 7px;
		}
		cursor: pointer;
		.effects {
			display: none;
			position: absolute;
			top: 0;
			left: 0px;
			right: 0;
			z-index: -1;
			img {
				max-width: 100%;
			}
			animation: zoomIn 0.5s;
			-webkit-animation: zoomIn 0.5s;
			-moz-animation: zoomIn 0.5s;
		}
		&.active {
			animation: facdeIn 0.3s;
			-webkit-animation: facdeIn 0.3s;
			-moz-animation: facdeIn 0.3s;
			background: linear-gradient(
					180deg,
					rgba(194, 177, 133, 0.45) 0%,
					rgba(134, 117, 72, 0.45) 35.42%,
					rgba(194, 177, 133, 0.45) 41.67%,
					rgba(122, 105, 74, 0.45) 47.91%,
					rgba(16, 80, 122, 0.45) 47.92%,
					rgba(79, 56, 12, 0.45) 47.93%,
					rgba(80, 56, 11, 0.45) 100%
				),
				radial-gradient(
					50% 69.15% at 50% 100%,
					#fff2ae 0%,
					rgba(254, 232, 179, 0) 100%
				);
			// transform: matrix(-1, 0, 0, 1, 0, 0);
			position: relative;
			.effects {
				display: block;
			}
		}
	}
	@media (max-width: 767px) {
		li {
			font-size: 20px;
		}
	}
}
@keyframes zoomIn {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes zoomIn2 {
	0% {
		transform: scale(0.8);
	}
	100% {
		transform: scale(1);
	}
}
.roadmap-section,
.team-section {
	@media (max-width: 767px) {
		background-size: cover !important;
		background-attachment: fixed !important;
	}
}

.team-section {
	@media (max-width: 767px) {
		padding-bottom: 200px;
	}
	@media (max-width: 575px) {
		padding-bottom: 150px;
	}
	.container-fluid {
		position: relative;
		z-index: 1;
	}
}
html {
	scroll-behavior: smooth;
}
.text-yellow {
	color: var(--yellow-clr);
	&:hover {
		color: var(--yellow-clr);
	}
}
.join--use-on {
	color: var(--white-clr);
	&:hover {
		color: var(--yellow-clr);
	}
}
.join-txt {
	max-width: 440px;
	text-align: center;
	margin: 0 auto;
	font-size: 20px;
	line-height: 30px;
}
.nft-container {
	&::after,
	&::before {
		width: 100%;
		height: 5px;
		content: "";
		display: block;
		background: linear-gradient(
			90deg,
			rgba(194, 177, 133, 0) 0%,
			#c2b185d4 50%,
			rgba(194, 176, 133, 0) 100%
		);
	}
	padding: 0 15px;
	.nft-slide-wrapper {
		width: calc(100% - 100px);
		max-width: 1466px;
		margin: 0 auto;
		padding: 10px 0 30px;
		.slick-arrow {
			display: none !important;
		}
		@media (max-width: 991px) {
			padding: 0;
		}
		@media (max-width: 767px) {
			width: 100%;
		}
		* {
			&:focus,
			&:focus-visible,
			&:focus-within {
				outline: none !important;
				box-shadow: none !important;
			}
		}
	}
	position: relative;
}
.banner-section {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.banner-link-whitepaper {
	position: absolute;
	right: 20px;
	bottom: 20px;
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	z-index: 99;
	text-transform: capitalize;
	&:hover {
		color: #fff;
	}
	@media (min-width: 768px) {
		right: 74px;
		bottom: 24px;
	}
}
.nft-slide-item {
	img {
		max-width: 100%;
		height: 300px;
		object-fit: contain;
		transition: all ease 0.3s;
		@media (max-width: 500px) {
			height: unset;
		}
	}
	@media (max-width: 500px) {
		padding: 25px 10px;
	}
	* {
		&:focus-visible {
			outline: none !important;
			box-shadow: none !important;
		}
	}
	&:hover {
		img {
			transform: scale(1.1);
		}
	}
}
.whitelisted-btn {
	font-size: 24px;
	line-height: 30px;
}
.discord {
	overflow: hidden;
	border: 1px solid var(--base-clr);
	border-radius: 25px;
	padding: 6px 25px 7px;
	font-size: 24px;
	color: var(--white-clr);
	margin-top: 30px;
	display: inline-block;
	min-width: 150px;
	background: rgba(#000, 0.25);
	&:before {
		content: "";
		inset: 0;
		position: absolute;
		background: linear-gradient(-45deg, #ee7752, #de359c, #23a6d5, #23d5ab);
		background-size: 400% 400%;
		animation: gradient 5s ease infinite;
		opacity: 0;
		transition: all ease 0.4s;
	}
	span {
		position: relative;
		z-index: 2;
	}
	position: relative;
	&:hover {
		border-color: transparent;
		color: var(--white-clr);
		&::before {
			opacity: 1;
		}
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.nav-btn {
	font-size: 90px;
	font-weight: 400;
	position: absolute;
	top: 50%;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -45px;
	color: var(--base-clr);
	cursor: pointer;
	text-align: center;
	&.prev {
		left: 0;
	}
	&.next {
		right: 0;
	}
	@media (max-width: 1399px) {
		width: 50px;
	}
	@media (max-width: 767px) {
		display: none;
	}
}
@keyframes upDown {
	0% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(-20px);
	}
}
